import { createGlobalStyle } from 'styled-components'
import { helveticaStack, monoSpaceStack, serifStack } from './variables'
import normalizeCss from './normalizeCss'
import codeStyles from './codeStyles'

export const GlobalStyle = createGlobalStyle({
  ...normalizeCss,
  ...codeStyles,

  // Base styles/resets
  ':root': {
    // Colors
    '--black': 'rgb(50, 50, 50)',
    '--gray-darkest': 'rgb(90, 90, 110)',
    '--gray-dark': 'rgb(140, 140, 150)',
    '--gray': 'rgb(220, 220, 220)',
    '--gray-light': 'rgb(230, 230, 230)',
    '--gray-lightest': 'rgb(242, 242, 235)',
    '--white': 'rgb(255, 255, 255)',

    '--green-light': 'rgb(51, 255, 204)',
    '--green': 'rgb(21, 225, 174)',
    '--green-dark': '#32665e',

    '--pink': '#F8D6C9',
    '--yellow-2': 'rgb(220, 220, 100)',
    '--yellow-light': '#fbf8cd',
    '--yellow': '#ffc96b',
    '--yellow-dark': '#fca30a',

    '--red': 'rgb(180, 40, 40)',

    // Dimensions
    '--spacing-s': '0.40625rem',
    '--spacing-m': '0.8125rem',
    '--spacing-l': '1.08333rem',

    // Fonts
    '--sans-serif': helveticaStack.join(', '),
    '--serif': serifStack.join(', '),
    '--mono-space': monoSpaceStack.join(', '),

    // Font sizes
    '--font-small': '80%',
    '--font-medium': '90%',

    // Line heights
    '--line-height-small': '1.2',

    // Borders
    '--border-radius': '5px'
  },
  html: {
    boxSizing: 'border-box',
    fontFamily: 'var(--sans-serif)',
    fontSize: '100%',
    lineHeight: '1.625',
    overflowY: 'scroll',
    scrollBehavior: 'smooth'
  },
  body: {
    fontFeatureSettings: "'kern', 'liga', 'clig', 'calt'",
    margin: 0
  },
  '*': {
    boxSizing: 'border-box'
  },
  '*:before': {
    boxSizing: 'inherit'
  },
  '*:after': {
    boxSizing: 'inherit'
  },

  // Links
  a: {
    color: 'var(--green-dark)',
    textDecoration: 'none'
  },
  'a:hover, a:active': {
    textDecoration: 'underline'
  },
  'h1 a, h2 a, h3 a': {
    color: 'var(--black)'
  },

  // Paragraphs
  p: {
    letterSpacing: '1.5px',
    marginBottom: 'var(--spacing-m)',
    marginTop: 0
  },
  'p *:last-child': {
    marginBottom: 0
  },

  // Headings
  'h1, h2, h3, h4, h5, h6': {
    color: 'inherit',
    lineHeight: 1.1,
    marginTop: 0,
    textRendering: 'optimizeLegibility'
  },
  h1: {
    fontSize: '2rem',
    marginBottom: '1.2rem'
  },
  h2: {
    fontSize: '1.5rem',
    marginBottom: 'var(--spacing-m)'
  },
  h3: {
    fontSize: '1.3rem',
    marginBottom: 'var(--spacing-m)'
  },
  h4: {
    fontSize: '1.1rem',
    marginBottom: 'var(--spacing-m)'
  },
  h5: {
    fontSize: '0.95rem',
    marginBottom: 'var(--spacing-m)'
  },
  h6: {
    fontSize: '0.85rem',
    marginBottom: 'var(--spacing-m)'
  },

  // Form elements
  'button, input, optgroup, select, textarea': {
    font: 'inherit',
    margin: 0
  },
  '::-webkit-input-placeholder': {
    color: 'inherit',
    opacity: 0.54
  },
  '::-webkit-file-upload-button': {
    '--webkit=appearance': 'button',
    font: 'inherit'
  },

  // Lists
  'ul, ol': {
    marginBottom: 'var(--spacing-m)',
    marginLeft: '1.6rem',
    marginRight: 0,
    marginTop: 0,
    paddingLeft: 0
  },
  'ul li': {
    '::marker': {
      fontSize: '140%',
      lineHeight: 0
    }
  },
  'ol li': {
    '::marker': {
      fontWeight: 'bold'
    }
  },
  li: {
    marginBottom: 'calc(var(--spacing-m) / 2)',
    letterSpacing: '1.5px'
  },
  'li > ol': {
    marginLeft: '1.6rem',
    marginBottom: 'calc(var(--spacing-m) / 2)',
    marginTop: 'calc(var(--spacing-m) / 2)'
  },
  'li > ul': {
    marginLeft: '1.6rem',
    marginBottom: 'calc(var(--spacing-m) / 2)',
    marginTop: 'calc(var(--spacing-m) / 2)'
  },
  'li *:last-child': {
    marginBottom: 0
  },
  'li > p': {
    marginBottom: 'calc(var(--spacing-m) / 2)'
  },
  'dd .gatsby-highlight, li .gatsby-highlight': {
    backgroundColor: 'var(--gray-lightest)',
    fontSize: '90%',
    margin: '0 0 10px 0',
    width: '100%'
  },
  'dd .gatsby-highlight:first-of-type, li .gatsby-highlight:first-of-type': {
    marginTop: '10px'
  },
  'dd .gatsby-highlight:last-of-type, li .gatsby-highlight:last-of-type': {
    marginBottom: 0
  },
  "dd pre[class*='language-'], li pre[class*='language-']": {
    padding: '10px'
  },
  dl: {
    letterSpacing: '1.5px',
    marginBottom: 'var(--spacing-m)',
    marginTop: 0
  },
  dt: {
    fontWeight: 'bold'
  },
  'dt sup': {
    fontWeight: 'normal'
  },
  // 1. Make the font bold, there is no bold version of Monaco
  'dt code': {
    fontWeight: 'normal',
    letterSpacing: '2px',
    textShadow: `0.2px 0.2px 0 var(--black),
      -0.2px -0.2px 0 var(--black),
      0.2px -0.2px 0 var(--black),
      -0.2px 0.2px 0 var(--black)` /* 1 */
  },
  'details .gatsby-highlight:last-child': {
    marginBottom: 0
  },
  dd: {
    marginBottom: '10px',
    marginLeft: '20px'
  },
  'dd dt': {
    marginTop: '10px'
  },

  // Quoting
  blockquote: {
    borderLeft: '4px solid var(--gray)',
    color: 'var(--gray-darkest)',
    marginBottom: 'var(--spacing-m)',
    marginLeft: 0,
    paddingLeft: 'var(--spacing-m)'
  },
  'blockquote *:last-child': {
    marginBottom: 0
  },
  q: {
    fontStyle: 'italic'
  },

  // Tables
  table: {
    borderCollapse: 'collapse',
    marginBottom: '20px',
    width: '100%'
  },
  'tr ul': {
    marginBottom: 0,
    letterSpacing: 'initial'
  },
  'tr li': {
    letterSpacing: 'initial'
  },
  'tr li:last-of-type': {
    marginBottom: 0
  },
  thead: {
    textAlign: 'left'
  },
  th: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    position: 'sticky',
    top: 0
  },
  td: {
    border: '1px solid var(--gray-light)',
    margin: 0
  },
  'td, th': {
    fontFeatureSettings: 'tnum',
    padding:
      'var(--spacing-m) var(--spacing-l) calc(var(--spacing-m) - 1px) var(--spacing-l)'
  },

  // Images
  // 1. Removes the weird margin.
  'img, video, iframe': {
    verticalAlign: 'bottom' /* 1 */
  },
  svg: {
    verticalAlign: 'text-top' /* 1 */
  },
  'svg:not(:root)': {
    overflow: 'hidden'
  },

  // Misc
  'abbr[title]': {
    borderBottom: '1px dotted var(--black)',
    textDecoration: 'none',
    cursor: 'help'
  },
  "details pre[class*='language-']": {
    marginBottom: 0
  },
  'details .gatsby-highlight': {
    marginLeft: '-15px',
    width: 'calc(100% + 30px)'
  }
})
