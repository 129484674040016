import styled from 'styled-components'
import { Link } from 'gatsby'
import { PAGE_WIDTH } from '../../utils/variables'
import { resetButton } from '../../utils/style-utils'
import propFilter from '../../helpers/propFilter'

export const HeaderWrap = styled.header({
  margin: '-20px -20px 20px -20px',
  padding: '20px',
  backgroundColor: 'var(--green-light)'
})

export const InnerHeader = styled.div({
  maxWidth: PAGE_WIDTH
})

export const TitleWrap = styled.div({})

export const Title = styled.h1({
  marginBottom: 0,
  marginRight: '10px',
  display: 'inline'
})

export const Tagline = styled.p({
  fontSize: 'var(--font-small)',
  marginBottom: '5px'
})

export const TitleExtension = styled.p({
  fontSize: 'var(--font-small)',
  marginBottom: '5px',
  display: 'inline',
  fontWeight: 'bold'
})

const current = {
  borderBottom: `solid 3px var(--black)`
}

const link = {
  color: 'var(--black)',
  fontWeight: 'bold',
  marginRight: '10px',

  ':hover': {
    textDecoration: 'none',
    borderBottom: `solid 3px var(--black)`
  }
}

export const NavLink = styled(propFilter(Link, ['slug', 'path']))(props => ({
  ...(props.slug === props.path && current),
  ...link
}))

// 1. For the underline to align with the other links.
export const SearchOpeningButton = styled.button(props => ({
  ...resetButton,
  ...link,
  ...(props.searchOpen && current),
  lineHeight: 1.1 /* 1 */
}))
