import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import useMetadata from '../../hooks/useMetadata'
import Search from '../Search'
import {
  HeaderWrap,
  InnerHeader,
  NavLink,
  SearchOpeningButton,
  Tagline,
  Title,
  TitleWrap,
  TitleExtension
} from './styles'

const Header = ({ pathName }) => {
  const [searchOpen, setSearchOpen] = React.useState(false)
  const { description, title, siteName } = useMetadata()

  const handleClick = () => {
    setSearchOpen(prevState => !prevState)
  }

  const handleKeyboard = ({ key, repeat, metaKey, ctrlKey }) => {
    if (repeat) return
    if ((metaKey || ctrlKey) && key === '/') setSearchOpen(state => !state)
    if (key === 'Escape') setSearchOpen(false)
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyboard)

    return () => document.removeEventListener('keydown', handleKeyboard)
  })

  return (
    <HeaderWrap>
      <InnerHeader>
        <TitleWrap>
          <Title>
            <Link to="/">{siteName}</Link>
          </Title>
          <TitleExtension>{title}</TitleExtension>
        </TitleWrap>
        <Tagline>{description}</Tagline>
        <nav>
          <NavLink path={pathName} slug="/" to="/">
            Home
          </NavLink>
          {process.env.NODE_ENV === 'development' && (
            <NavLink path={pathName} slug="/drafts" to="/drafts">
              Drafts
            </NavLink>
          )}
          <NavLink path={pathName} slug="/categories" to="/categories">
            Categories
          </NavLink>
          <NavLink path={pathName} slug="/tags" to="/tags">
            Tags
          </NavLink>
          <SearchOpeningButton searchOpen={searchOpen} onClick={handleClick}>
            Search
          </SearchOpeningButton>
        </nav>
        {searchOpen && <Search />}
      </InnerHeader>
    </HeaderWrap>
  )
}

Header.propTypes = {
  pathName: PropTypes.string
}

export default Header
