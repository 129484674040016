import styled from 'styled-components'

export const Field = styled.input({
  marginBottom: '5px',
  padding: '8px 15px',
  border: 'none',
  width: '100%',

  ':focus': {
    outline: `2px solid var(--green)`
  }
})
