// Widths
export const PAGE_WIDTH = '50em'
export const HEADER_HEIGHT = '127px'

// Breakpoints
export const SMALL_SCREEN = '@media (min-width: 600px)'
export const LARGE_SCREEN = '@media (min-width: 800px)'

export const helveticaStack = [
  '"Helvetica Neue"',
  'HelveticaNeue',
  'Helvetica',
  'Arial',
  'sans-serif'
]

export const monoSpaceStack = [
  'Consolas',
  'Monaco',
  '"Andale Mono"',
  '"Ubuntu Mono"',
  'monospace'
]

export const serifStack = ['Georgia', 'serif']
