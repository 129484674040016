import React from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import VisuallyHidden from '../VisuallyHidden'
import { isFunctionalKey } from '../../utils/keys'
import Emoji from '../Emoji'
import SearchField from '../SearchField'
import {
  CloseButton,
  CloseIcon,
  Error,
  InnerSearch,
  SearchEngineLogo,
  SearchLink,
  SearchList,
  SearchListItem,
  SearchResults,
  SearchWrap,
  SmallText
} from './styles'

const AriaStatus = props => {
  const { cursor, hits = [] } = props

  if (hits.length < 1) return null

  return (
    <VisuallyHidden role="status" aria-live="assertive" aria-atomic="true">
      {hits[cursor].title}, list item {cursor} of {hits.length - 1}
    </VisuallyHidden>
  )
}

AriaStatus.propTypes = {
  cursor: PropTypes.number.isRequired,
  hits: PropTypes.array.isRequired
}

const CryingFace = () => <Emoji symbol="😢" aria-label="crying face" />

const searchMessage = searchState => {
  switch (searchState) {
    case 'searching':
      return 'Searching...'
    case 'noResults':
      return (
        <span>
          Nothing found <CryingFace />
        </span>
      )
    case 'error':
      return (
        <Error>
          There was an error
          <CryingFace />
        </Error>
      )
    default:
      return 'Type some words into that box human. Humans love words.'
  }
}

const SearchDescription = ({ searchState }) => (
  <SmallText id="search-field-description">
    {searchMessage(searchState)}
  </SmallText>
)

const Search = () => {
  const [hits, setHits] = React.useState([])
  const [cursor, setCursor] = React.useState(0)
  const [searchFieldValue, setSearchFieldValue] = React.useState('')
  const [searchState, setSearchState] = React.useState('resting')

  const handleError = error => {
    setSearchState('error')
    console.log('handleError', error)
  }

  const handleResults = async ({ hits }) => {
    if (hits.length > 0) {
      await setHits(hits)
      setSearchState('resting')
      return
    }

    setSearchState('noResults')
  }

  const handleEmptyField = () => {
    setHits([])
  }

  const handleTyping = event => {
    setSearchFieldValue(event.target.value)
  }

  const handleFieldClearing = () => {
    setSearchFieldValue('')
    setHits([])
    setSearchState('resting')
  }

  const handleLoadingState = searchState => {
    setSearchState(searchState)
  }

  const handleKeyboard = event => {
    const { keyCode, target } = event
    const isUp = keyCode === 38
    const isDown = keyCode === 40
    const isEsc = keyCode === 27
    const isEnter = keyCode === 13
    const isFuncKey = isFunctionalKey(keyCode)
    const hitsCount = hits.length - 2
    const hasMoreResults = cursor <= hitsCount
    const shouldHighlightNextItem = isDown && hasMoreResults
    const shouldHighlightPreviousItem = isUp && cursor

    if (shouldHighlightNextItem) setCursor(prevState => prevState + 1)
    if (shouldHighlightPreviousItem) setCursor(prevState => prevState - 1)
    // Set the typed search string into the state.
    if (!isFuncKey && target.value) handleTyping(target.value)
    if (isEnter && hits.length > 0) navigate(hits[cursor].slug)
    if (isEsc) handleFieldClearing()
  }

  const ariaActive = `search-results-${hits.length}-item-${cursor}`

  const renderResult = ({ hit = {}, index }) => {
    const isSelected = cursor === index

    return (
      <SearchListItem
        aria-selected={isSelected}
        id={ariaActive}
        key={hit.objectID}
      >
        <SearchLink isSelected={isSelected} to={hit.slug}>
          {hit.title}
        </SearchLink>
      </SearchListItem>
    )
  }

  return (
    <SearchWrap>
      <InnerSearch>
        <VisuallyHidden>
          <label id="search-field-label" htmlFor="search-field">
            Search:
          </label>
        </VisuallyHidden>
        <SearchField
          fieldProps={{
            id: 'search-field',
            placeholder: 'Type or die...',
            autoComplete: 'off',
            'aria-labelledby': 'search-field-description search-field-label',
            'aria-activedescendant': ariaActive
          }}
          handleResults={handleResults}
          handleEmptyField={handleEmptyField}
          handleError={handleError}
          handleKeyboard={handleKeyboard}
          handleTyping={handleTyping}
          handleFieldClearing={handleFieldClearing}
          handleLoadingState={handleLoadingState}
          searchFieldValue={searchFieldValue}
        />
        <SearchDescription searchState={searchState} />
        <CloseButton
          aria-label="Reset the form"
          onClick={handleFieldClearing}
          type="reset"
        >
          <CloseIcon $isSearching={searchState === 'searching'} />
        </CloseButton>
      </InnerSearch>
      <SearchResults
        aria-hidden={!hits.length}
        isVisible={Boolean(hits.length)}
      >
        <SearchList id="search-results">
          {hits.map((hit, index) => renderResult({ hit, index }))}
        </SearchList>
        <SearchEngineLogo />
      </SearchResults>
      <AriaStatus cursor={cursor} hits={hits} />
    </SearchWrap>
  )
}

SearchDescription.propTypes = {
  searchState: PropTypes.string.isRequired
}

export default Search
