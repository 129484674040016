import { SMALL_SCREEN } from '@utils/variables'

const syntaxHighlighting = {
  // Code output
  'code, kbd, pre, samp': {
    fontSize: '0.85em'
  },
  'pre code': {
    fontSize: '1em',
    backgroundColor: 'initial',
    padding: 0
  },
  samp: {
    fontSize: '0.85rem',
    lineHeight: '1.625rem'
  },
  kbd: {
    backgroundColor: 'var(--gray-light)',
    border: '1px solid #b4b4b4',
    borderRadius: '3px',
    boxShadow:
      '0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 0 0 rgba(255, 255, 255, 0.7) inset',
    color: 'var(--black)',
    display: 'inline-block',
    fontFamily: 'var(--mono-space)',
    lineHeight: 1,
    padding: '2px 4px',
    whitespace: 'nowrap'
  },

  // Syntax highlighting
  '.gatsby-highlight': {
    backgroundColor: 'rgb(230, 230, 230)',
    marginBottom: '20px',
    marginLeft: '-20px',
    maxHeight: '600px',
    overflow: 'auto',
    position: 'relative',
    width: 'calc(100% + 40px)',

    [SMALL_SCREEN]: {
      width: 'calc(100% + 20px)'
    }
  },
  "code[class*='language-'], pre[class*='language-']": {
    color: 'var(--black)',
    fontFamily: 'var(--mono-space)',
    hyphens: 'none',
    lineHeight: 1.5,
    tabSize: 2,
    textAlign: 'left',
    whiteSpace: 'pre',
    wordBreak: 'normal',
    wordSpacing: 'normal',
    wordWrap: 'normal'
  },
  "pre[class*='language-']": {
    margin: 0,
    padding: '20px 0 20px 20px',
    position: 'relative'
  },
  "pre[class*='language-'] > code": {
    position: 'relative'
  },
  code: {
    backgroundColor: 'var(--gray-lightest)',
    borderRadius: 'var(--border-radius)',
    display: 'inline',
    fontFamily: 'var(--mono-space)',
    padding: '1px 4px 4px 5px'
  },

  // Inline codeblocks
  ":not(pre) > code[class*='language-']": {
    display: 'inline',
    position: 'relative',
    whiteSpace: 'normal'
  },
  '.token.comment, .token.block-comment, .token.prolog, .token.doctype, .token.cdata':
    {
      color: '#7d8b99'
    },
  '.token.punctuation': {
    color: '#5f6364'
  },
  '.token.property, .token.tag, .token.boolean, .token.number, .token.function-name, .token.constant, .token.symbol, .token.deleted':
    {
      color: '#c92c2c'
    },
  '.token.selector, .token.attr-name, .token.string, .token.char, .token.function, .token.builtin, .token.inserted':
    {
      color: '#2f9c0a'
    },
  '.token.operator, .token.entity, .token.url, .token.variable': {
    color: '#a67f59'
  },
  '.token.atrule, .token.attr-value, .token.keyword, .token.class-name': {
    color: '#1990b8'
  },
  '.token.regex, .token.important': {
    color: '#e90'
  },
  '.language-css .token.string, .style .token.string': {
    color: '#a67f59',
    background: 'rgba(255, 255, 255, 0.5)'
  },
  '.token.important': {
    fontWeight: 'normal'
  },
  '.token.bold': {
    fontWeight: 'bold'
  },
  '.token.italic': {
    fontStyle: 'italic'
  },
  '.token.entity': {
    cursor: 'help'
  },
  '.namespace': {
    opacity: 0.7
  },
  '@media screen and (max-width: 767px)': {
    "pre[class*='language-']:before, pre[class*='language-']:after": {
      bottom: '14px',
      boxShadow: 'none'
    }
  },
  '.token.tab:not(:empty):before, .token.cr:before, .token.lf:before': {
    color: '#e0d7d1'
  },
  "pre[class*='language-'].line-numbers code": {
    paddingLeft: '25px',
    display: 'block'
  },
  '.line-numbers .line-numbers-rows': {
    pointerEvents: 'none',
    position: 'absolute',
    top: '20px',
    userSelect: 'none'
  },
  '.line-numbers-rows > span': {
    counterIncrement: 'linenumber',
    display: 'block'
  },
  '.line-numbers-rows > span:before': {
    color: '#999',
    content: 'counter(linenumber)',
    display: 'block',
    paddingLeft: '0.6em',
    textAlign: 'right'
  },
  '.gatsby-highlight-code-line': {
    backgroundColor: '#feb',
    display: 'block',
    marginLeft: '-40px',
    paddingLeft: '40px',
    paddingRight: '20px',
    width: 'calc(100% + 40px)'
  }
}

export default syntaxHighlighting
