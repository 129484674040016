import styled from 'styled-components'

export const BaseComponent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '20px 20px 0 20px',
  minHeight: 'calc(100vh - 40px)'
})

const widths = {
  narrowest: '40em',
  narrow: '50em',
  base: '60em',
  wide: '100%'
}

export const Main = styled.div(props => ({
  flex: 1,
  maxWidth: widths[props.size] || widths.base
}))
