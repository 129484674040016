import styled from 'styled-components'
import { PAGE_WIDTH } from '../../utils/variables'

export const FooterComponent = styled.footer({
  margin: '20px -20px -20px -20px',
  padding: '20px',
  backgroundColor: 'var(--gray)'
})

const footer = {
  backgroundColor: 'var(--gray)',
  contentVisibility: 'auto',
  intrinsicSize: '0 230px',
  margin: '20px -20px -20px -20px',
  padding: '20px'
}

export const InnerFooter = styled.div({
  maxWidth: PAGE_WIDTH
})

const innerFooter = {
  maxWidth: PAGE_WIDTH
}

export const List = styled.ul({
  margin: '0 0 0 20px'
})

export const ListItem = styled.li({
  fontSize: '80%',
  letterSpacing: 'initial'
})

const list = {
  fontSize: '80%',
  margin: '0 0 0 20px'
}

export default {
  footer,
  innerFooter,
  list
}
