import React from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import algoliaSearch from 'algoliasearch/lite'
import { isFunctionalKey } from '../../utils/keys'
import { Field } from './styles'

const ALGOLIA_OPTIONS = { hitsPerPage: 10 }
const DEBOUNCE_TIME = 500

// Initiate the Algolia search client.
const algoliaClient = algoliaSearch(
  process.env.ALGOLIA_APP_ID,
  process.env.ALGOLIA_SEARCH_ONLY_API_KEY
)

const SearchField = props => {
  let algoliaIndex
  let debouncedSearch
  const searchField = React.useRef(null)

  const ajaxCall = async value => {
    try {
      const results = await algoliaIndex.search(value, ALGOLIA_OPTIONS)
      props.handleResults(results || [])
    } catch (error) {
      props.handleError(error)
    }
  }

  React.useEffect(() => {
    // Initiate the index when we mount.
    algoliaIndex = algoliaClient.initIndex(process.env.ALGOLIA_INDEX_NAME)
    debouncedSearch = debounce(ajaxCall, DEBOUNCE_TIME)
    // Move focus to the field.
    if (searchField.current) searchField.current.focus()

    // The return value gets called when the component un-mounts.
    return debouncedSearch.cancel()
  }, [searchField.current, ajaxCall])

  const handleKeyup = event => {
    if (isFunctionalKey(event.keyCode)) props.handleKeyboard(event)
  }

  const handleChange = event => {
    const { value } = event.target
    props.handleTyping(event)

    // `persist` prevents the pooling of the events, this is important because
    // of the debouncing.
    event.persist()

    if (value.length > 1) {
      props.handleLoadingState('searching')
      debouncedSearch(value)
    } else {
      props.handleEmptyField()
    }
  }

  return (
    <Field
      {...props.fieldProps}
      onKeyUp={handleKeyup}
      onChange={handleChange}
      value={props.searchFieldValue}
      ref={searchField}
      type="text"
    />
  )
}

SearchField.defaultProps = {
  options: {},
  fieldProps: {}
}

SearchField.propTypes = {
  fieldProps: PropTypes.object,
  handleResults: PropTypes.func,
  handleError: PropTypes.func,
  handleEmptyField: PropTypes.func,
  handleKeyboard: PropTypes.func,
  handleTyping: PropTypes.func,
  handleLoadingState: PropTypes.func,
  searchFieldValue: PropTypes.string
}

export default SearchField
