import React from 'react'
import PropTypes from 'prop-types'
import Header from '../Header'
import Helmet from 'react-helmet'
import Footer from '../Footer'
import { GlobalStyle } from '../../utils/GlobalStyles'
import { BaseComponent, Main } from './styles'

const Base = props => (
  <BaseComponent>
    <GlobalStyle />
    <Helmet htmlAttributes={{ lang: 'en' }} />
    <Header pathName={props.pathName} />
    <Main size={props.size}>{props.children}</Main>
    <Footer />
  </BaseComponent>
)

Base.defaultProps = {
  size: 'base'
}

Base.propTypes = {
  pathName: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['wide', 'narrow', 'narrowest', 'base'])
}

export default Base
