import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import useMetadata from '../../hooks/useMetadata'

const Meta = ({ pageTitle, location, description }) => {
  const {
    siteName,
    description: mainDescription,
    isIndexed,
    siteUrl
  } = useMetadata()
  const robotsContent = isIndexed
    ? 'follow, index'
    : 'nofollow, noindex, noarchive, nocache, nosnippet, noimageindex'
  const origin = location?.origin || siteUrl
  const pathname = location?.pathname === '/' ? '' : location?.pathname
  const canonicalUrl = origin + pathname
  const pageDescription = description || mainDescription

  return (
    <Helmet>
      {/* Standard metadata */}
      <title>
        {pageTitle} – {siteName}
      </title>
      <meta name="description" content={pageDescription} />
      <meta name="robots" content={robotsContent} />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      {/* Open graph */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={origin + '/images/og-image.jpg'} />

      {/* Twitter card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@hiljaa" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content={origin + '/images/og-image.jpg'} />

      {/* Favicon */}
      <link rel="shortcut icon" href="/images/favicon/favicon.ico" />
      <link
        href="/images/favicon/favicon-16x16.png"
        rel="icon"
        sizes="16x16"
        type="image/png"
      />
      <link
        href="/images/favicon/favicon-32x32.png"
        rel="icon"
        sizes="32x32"
        type="image/png"
      />
      <link rel="manifest" href="/images/favicon/manifest.json" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="theme-color" content="#fff" />
      <meta name="application-name" content="clubmate-dot-fi" />
      <link
        href="/images/favicon/apple-touch-icon-57x57.png"
        rel="apple-touch-icon"
        sizes="57x57"
      />
      <link
        href="/images/favicon/apple-touch-icon-60x60.png"
        rel="apple-touch-icon"
        sizes="60x60"
      />
      <link
        href="/images/favicon/apple-touch-icon-72x72.png"
        rel="apple-touch-icon"
        sizes="72x72"
      />
      <link
        href="/images/favicon/apple-touch-icon-76x76.png"
        rel="apple-touch-icon"
        sizes="76x76"
      />
      <link
        href="/images/favicon/apple-touch-icon-114x114.png"
        rel="apple-touch-icon"
        sizes="114x114"
      />
      <link
        href="/images/favicon/apple-touch-icon-120x120.png"
        rel="apple-touch-icon"
        sizes="120x120"
      />
      <link
        href="/images/favicon/apple-touch-icon-144x144.png"
        rel="apple-touch-icon"
        sizes="144x144"
      />
      <link
        href="/images/favicon/apple-touch-icon-152x152.png"
        rel="apple-touch-icon"
        sizes="152x152"
      />
      <link
        href="/images/favicon/apple-touch-icon-167x167.png"
        rel="apple-touch-icon"
        sizes="167x167"
      />
      <link
        href="/images/favicon/apple-touch-icon-180x180.png"
        rel="apple-touch-icon"
        sizes="180x180"
      />
      <link
        href="/images/favicon/apple-touch-icon-1024x1024.png"
        rel="apple-touch-icon"
        sizes="1024x1024"
      />
      <meta content="yes" name="apple-mobile-web-app-capable" />
      <meta
        content="black-translucent"
        name="apple-mobile-web-app-status-bar-style"
      />
      <meta content="clubmate-dot-fi" name="apple-mobile-web-app-title" />
      <link
        href="/images/favicon/apple-touch-startup-image-320x460.png"
        media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 1)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/favicon/apple-touch-startup-image-640x920.png"
        media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/favicon/apple-touch-startup-image-640x1096.png"
        media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/favicon/apple-touch-startup-image-750x1294.png"
        media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/favicon/apple-touch-startup-image-1182x2208.png"
        media="(device-width: 414px) and (device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/favicon/apple-touch-startup-image-1242x2148.png"
        media="(device-width: 414px) and (device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/favicon/apple-touch-startup-image-748x1024.png"
        media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 1)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/favicon/apple-touch-startup-image-768x1004.png"
        media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/favicon/apple-touch-startup-image-1496x2048.png"
        media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/favicon/apple-touch-startup-image-1536x2008.png"
        media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
    </Helmet>
  )
}

Meta.propTypes = {
  description: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    origin: PropTypes.string
  })
}

export default Meta
