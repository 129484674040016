import styled from 'styled-components'
import { Link } from 'gatsby'
import Cross from '../../icons/Cross'
import AlgoliaLogo from '../../icons/Algolia'
import { resetList, resetButton } from '../../utils/style-utils'
import propFilter from '../../helpers/propFilter'

export const SearchWrap = styled.div({
  marginTop: '10px',
  position: 'relative'
})

export const InnerSearch = styled.div({
  margin: 0,
  position: 'relative'
})

export const SearchResults = styled.div(props => ({
  backgroundColor: 'rgba(230, 230, 230, 0.98)',
  display: props.isVisible ? 'block' : 'none',
  position: 'absolute',
  top: '42px',
  zIndex: 10,
  width: '100%'
}))

export const SearchList = styled.ul({
  ...resetList
})

export const SearchEngineLogo = styled(AlgoliaLogo)({
  margin: '5px 15px'
})

export const SearchListItem = styled.li({
  margin: 0,

  ':last-of-type a': {
    borderBottom: 'none'
  }
})

export const SearchLink = styled(propFilter(Link, ['isSelected']))(props => ({
  display: 'block',
  color: 'var(--black)',
  borderBottom: '1px solid var(--gray)',
  padding: '10px 15px 10px 15px',
  margin: 0,

  ...(props.isSelected && { backgroundColor: 'var(--yellow)' })
}))

export const CloseButton = styled.button({
  ...resetButton,
  cursor: 'pointer',
  height: '16px',
  position: 'absolute',
  right: '12px',
  top: '11px',
  width: '16px'
})

// const spin = keyframes({
//   '100%': {
//     transform: 'rotate(360deg)'
//   }
// })

const searching = {
  // animationName: spin,
  animationDuration: '1000ms',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear'
}

export const CloseIcon = styled(Cross)(props => ({
  fill: 'var(--gray)',
  ...(props.$isSearching && searching),

  ':hover': {
    fill: 'var(--black)'
  }
}))

export const SmallText = styled.span({
  fontSize: '75%',
  marginBottom: 0,
  letterSpacing: '1px'
})

export const Error = styled.span({
  color: 'var(--red)'
})
