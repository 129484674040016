import { useStaticQuery, graphql } from 'gatsby'

const useMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query MetaQuery {
      site {
        siteMetadata {
          author
          description
          email
          isIndexed
          siteName
          siteUrl
          title
          twitterHandle
        }
      }
    }
  `)

  return site.siteMetadata
}

export default useMetadata
