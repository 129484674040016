import React from 'react'
import { Link } from 'gatsby'
import useMetadata from '../../hooks/useMetadata'
import { FooterComponent, InnerFooter, List, ListItem } from './styles'

const Footer = () => {
  const { author } = useMetadata()

  return (
    <FooterComponent>
      <InnerFooter>
        <List>
          <ListItem>
            &copy; {new Date().getFullYear()} <strong>{author}</strong>
          </ListItem>
          <ListItem>
            <Link to="/about">About</Link>
          </ListItem>
          <ListItem>All rights reserved yadda yadda.</ListItem>
          <ListItem>
            I can put just about anything here, no one reads the footer anyways.
          </ListItem>
          <ListItem>I love u!</ListItem>
        </List>
      </InnerFooter>
    </FooterComponent>
  )
}

export default Footer
